.form-display {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/pictures/jstutorials.jpg");
  background-position: center;
}
.form {
  min-height: 30%;
  width: 420px;
  color: white;
  backdrop-filter: blur(30px);
  padding: 2rem;
  line-height: 2cqw;
  border-radius: 20px;
  background-color: transparent;
  border: solid 2px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 1);

  &__title {
    font-size: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.form-display .input-box {
  position: relative;
  width: 100%;
  height: 3rem;
  margin: 30px 0;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  border-radius: 40px;
  border: 2px solid rgba(255, 255, 255, 2);
  padding: 20px 45px 20px 20px;
  color: white;
}

.input-box input::placeholder {
  color: white;
}

.input-box .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  color: white;
  transform: translateY(-50%);
  font-size: 16px;
}
.register-link {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.form-display .remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  margin: -15px 0 15px;
}

.remember-forgot input label {
  color: white;
  margin-right: 4px;
  text-decoration: none;
}
.remember-forgot a {
  color: white;
  text-decoration: none;
  font-weight: 600;
}
.form button {
  width: 100%;
  height: 45px;
  background-color: white;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: black;
  font-weight: 600;
}

.form .register-link {
  font-size: 14.5px;
  text-align: center;
  margin-top: 20px;
}

.register-link a {
  color: white;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}
