.carousel {
  // background-color: black;
}
.portfolio-title {
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5rem 0;
}

.card {
  width: 100%; /* Adjust this to control the width of the card relative to its parent container */
  max-width: 300px; /* Example: set a max-width to control the size */
  margin: 2rem 0 5rem 2rem;
  padding: 10px;
  background-color: #097969;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }
}
.wrapper-card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  // border-radius: 20px;
  color: white;
  padding: 1rem;
  // margin: 1rem;
}

.button-card {
  border: 1px solid white;
  border-radius: 20px;
  padding: 1rem;
  font-weight: 600;
  color: #097969;
  background-color: white;

  &:hover {
    background-color: black;
    color: white;
    transition: background-color 0.5s;
  }
}

.filter-blur {
  filter: blur(30px);
}

.undercostruction {
}
img {
  border-radius: 20px;
  max-height: 9rem;
  min-width: 100%;
}

h3 {
  font-weight: 600;
}
