@use "../../styles/mixins" as *;

.teaching {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  background-color: #f5f5f5;
}

.top-container {
  display: flex;
  flex-direction: column-reverse;
  padding: 5rem 0;
  @include tablet {
    flex-direction: row;
  }

  &__learning-title {
    display: flex;
    flex-direction: column;
    margin: 3rem 0 0 0;
    border: solid 1px lightgrey;
    border-radius: 20px;
    padding: 2rem;
  }
  &__menu-list {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
    font-size: 12px;
    gap: 10px;
    font-weight: 600;
    color: #097969;
  }

  &__teaching-title {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
  }

  &__text-content {
    margin: 0 1rem 1rem;
  }
  &__box-teaching-languages {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__box-tech-picture {
    max-width: 60rem;
    margin: 2rem 0;

    @include tablet {
      padding: 2rem;
    }
  }

  &__intro-explanation {
    display: flex;
    justify-content: center;
    width: 100%;
    @include tablet {
      align-items: center;
    }
  }
}

.span-title {
  color: #097969;
}
.icon-world {
  width: 1rem;
}

.technologies-picture {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.botton-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;

  &__main-content {
    border: solid 1px lightgrey;
    border-radius: 20px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include tablet {
      flex-direction: row;
    }
  }
  &__list-arguments {
    list-style-type: none;
    margin: 1rem 0 0 0;
    font-size: 14px;
  }
  &__item-list-studying {
    margin-bottom: 2rem;
  }
}

.payment {
  display: flex;
  flex-direction: column;
  border: solid 1px lightgrey;
  border-radius: 10px;
  padding: 1rem;
  margin: 2rem 0;
  width: 100%;

  &__box {
    margin: 2rem 0;
  }
  &__title {
    font-size: 2rem;
    font-weight: 600;
  }
  &__container-button {
    width: 100%;
    display: flex;
    justify-content: center;

    @include tablet {
      justify-content: flex-end;
    }
  }
  &__button {
    background-color: black;
    border-radius: 10px;
    padding: 1rem;
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    margin: 2rem 0;
    transition: 1s ease-in-out;

    &:hover {
      background-color: #097969;
    }
  }
}

.paypal {
  display: none;
  border: solid 1px lightgrey;
  border-radius: 10px;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  line-height: 3rem;
  font-weight: 600;
  font-size: 1.2rem;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
    }
  }
}
