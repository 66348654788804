*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: "PlayfairDisplay", sans-serif;
  min-width: 320px;
}
