@use "../../styles/mixins" as *;

.header {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  padding: 2rem;
  background-color: black;
  height: 9rem;
  transition: background-color 1s ease-out; /* Smooth slide down effect */

  &.active {
    background-color: #097969;
    height: 2rem;
  }
  &__logo {
    width: 5rem;
    border-radius: 60px;

    @include tablet {
      width: 7rem;
      &:hover {
        cursor: pointer;
        filter: grayscale(1);
      }
    }
  }
  &__navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include tablet {
      display: flex;
    }
  }
  &__nav-menu {
    position: fixed;
    left: -100%;
    top: 144px;
    // top: 9rem; /* Adjusted to match header height */

    flex-direction: column;
    background-color: gray;
    width: 100%;
    height: 100%;
    // height: calc(100% - 9rem);

    gap: 0;
    text-align: center;
    transition: 0.3s;
    padding: 0;

    &.active {
      left: 0;
      display: flex;
      justify-content: center;
    }

    @include tablet {
      position: static;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      background-color: transparent;
      left: auto;
      top: auto;
      width: auto;
      gap: 1rem;
      transition: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__nav-item {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    margin: 16px 0;
    border-bottom: solid 1px #097969;
    padding: 0;

    &:hover {
      transition: ease 1s;
      box-shadow: 0 0 8px #ffffff, 0 0 15px #ffffff, 0 0 20px #ffffff; // Glowing effect
    }

    @include tablet {
      margin: 0;
    }
  }

  &__nav-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    transition: 0.7s ease;
    &:hover {
      color: #097969;
    }
  }
}
.bar {
  display: block;
  width: 30px;
  height: 5px;
  margin: 3px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}
.hamburger {
  display: block;
  cursor: pointer;

  @include tablet {
    display: none;
  }
}
.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}
.hamburger.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
.hamburger.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
