@use "../../styles/mixins" as *;

.footer {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
    background-color: #097969;

    @include tablet {
      // flex-direction: row-reverse;
    }
  }

  &__box-contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
    line-height: 4rem;
  }
}
.nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 3rem;
}

.email {
  width: 3rem;
}
.botton-up {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  background-color: white;

  &__icon {
    width: 6rem;
    border: solid 5px #097969;
    background-color: #097969;
    border-radius: 58px;
  }
}
