@use "../../styles/mixins" as *;
@use "../../styles/fonts" as *;
@import "../../output.css";
html {
  scroll-behavior: smooth;
}

body {
  font-family: "metropolis", sans-serif;
}
.translation-button {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1.7rem;
  top: 10rem;
  border: solid 1px black;
  font-size: 1rem;
  border-radius: 5px;
  &__eng {
    background-color: #097969;
    color: white;
    transition: 0.5s ease-in;

    &:hover {
      background-color: black;
    }
  }
  &__ita {
    transition: 0.5s ease-in;
    &:hover {
      color: #097969;
    }
  }
  // @include tablet {
  //   font-size: 1.5rem;
  // }
}
.websitename {
  font-size: 5rem;
  color: black;
  height: 10rem;
  margin-top: 12rem;
  display: flex;

  justify-content: center;
  align-items: center;
  @include tablet {
    margin: 15rem 0 3rem;
  }

  &__title {
  }
  &__span {
    color: #097969;
  }
}
.wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;

  @include tablet {
    padding: 3rem;
    flex-direction: row;
  }

  &__video-container {
    min-width: 320px;
    margin: 2rem 0;

    @include tablet {
      min-width: 480px;
      padding: 2rem 0;
    }
    @include desktop {
      min-width: 640px;
    }
  }

  &__text {
    padding: 0;
    font-size: 1.3rem;
    font-weight: 600;
    @include tablet {
      line-height: 2rem;
    }
  }
}

.objectives {
  padding: 2rem;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;

  &__span {
    color: #097969;
  }
  &__list {
    list-style-type: none;
    line-height: 5rem;
    font-weight: 600;
  }
  &__list-items {
    font-size: 1rem;
    @include tablet {
      font-size: 1.5rem;
    }
    @include desktop {
      font-size: 2rem;
    }
  }
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  @include tablet {
    font-size: 2.5rem;
  }
  @include desktop {
    font-size: 4rem;
  }
}

.highlight {
  color: #097969 !important;
}

.playvideo {
  border-radius: 20px;
}

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem 2rem 10rem;
  margin-top: 2rem;
  background-color: white;
}

.cards-box {
  display: flex;
  background-color: black;
  width: 100%;
  &__products {
    display: flex;
    justify-content: center;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin-top: 2rem;
    width: 100%;

    @include tablet {
      flex-direction: row;
    }
  }
  &__list-item {
    background-color: #097969;
    border-radius: 10px;
    color: #d8d8d8;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
    margin: 1rem;
    max-width: 30rem;
    @include tablet {
      width: 14rem;
    }
    @include desktop {
      width: 16rem;
    }
  }

  &__body-container {
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    overflow: hidden;
    transition: 0.5s ease-in;

    &:hover {
      background-color: black;
      cursor: pointer;
    }

    @include tablet {
      padding: 1rem;
      font-size: 12px;
    }
  }

  &__paragraph {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
.card-image {
  width: 100%;
  border-radius: 10px;
  height: 10rem;
}
.card-image.second-project {
}
.card-image.third-project {
  filter: blur(50px);
}

.slider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.slide-button.left {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  cursor: pointer;
  color: white;
}
.slide-button.right {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  cursor: pointer;
  color: white;
}
